$.fn.initializeAutocomplete = function () {
    initializeTypicalAutocomplete($(this));
    initializeOneChoiceDropdown($(this));
    initializeMultichoiceDropdown($(this));

    function customizeAutocompletePresentation(element) {
        element.autocomplete("widget").removeClass('ui-front ui-menu ui-widget ui-widget-content ui-corner-all');
        element.autocomplete("widget").addClass('ri-autocomplete');
        element.autocomplete("widget").find("li a").each(function () {
            $(this).removeClass('ui-corner-all');
        });
        element.autocomplete("widget").css('min-width', '192px');
    }

    function initializeTypicalAutocomplete(element) {
        element.find('span[data-autocomplete]').each(function () {
            if ($(this).hasAttr('customed-autocomplete')) return;
            else $(this).attr('customed-autocomplete', 'true');

            var form_field = $(this).children('input, select').eq(0);

            form_field.autocomplete({
                options: null,
                source: function (request, response) {
                    $.post(form_field.parent().attr('data-autocomplete-url') + '&' + form_field.parent().attr('data-autocomplete-name') + '=' + form_field.val(), null, function (data) {
                        if (data.length == 0) resultsNumber = new Array("No items found");
                        else resultsNumber = new Array(data.length + " item(s) found");
                        data = resultsNumber.concat(data);
                        options = data;
                        response(data.slice(0, 11));
                    });
                },
                minLength: 2,
                select: function (event, ui) {

                },
                open: function (event, ui) {
                    $(this).autocomplete("widget").children('li').eq(0).addClass('ui-menu-description');
                    $(this).autocomplete("widget").children('li').eq(0).removeClass('ui-menu-item');
                    customizeAutocompletePresentation($(this));
                },
                change: function (event, ui) {
                    if (!ui.item) {
                        var value = $(this).val();
                        $(this).val("");

                        if (typeof options == 'undefined')
                            return;

                        if (options.length == 2) {
                            $(this).val(options[1]);
                        }
                        else {
                            for (var i = 0; i < options.length; i++) {
                                if (options[i].toLowerCase() == value.toLowerCase()) {
                                    $(this).val(value);
                                    break;
                                }
                            }
                        }
                    }
                }
            });
        });
    }


    function initializeOneChoiceDropdown(element) {
        element.find('span[data-editable-dropdown="True"][data-multichoice-dropdown="False"]').each(function () {
            if ($(this).hasAttr('customed-autocomplete')) return;
            else $(this).attr('customed-autocomplete', 'true');

            var select = $(this).children('select').eq(0);
            var form_field = $('<input>').prependTo($(this));

            var attributes = select.prop("attributes");

            form_field.autocomplete_options = new Array();
            select.find('option').each(function () {
                form_field.autocomplete_options[form_field.autocomplete_options.length] = $(this).val();
            });

            form_field.autocomplete_options_descriptions = new Object();
            select.find('option').each(function () {
                form_field.autocomplete_options_descriptions[$(this).val()] = $(this).html();
            });

            $.each(attributes, function () {
                form_field.attr(this.name, this.value);
            });

            form_field.attr('value', select.find('option[selected]').html());
            select.remove();

            form_field.on("change", function (event, missing) {
                if (missing) form_field.change_triggered = false;
                else form_field.change_triggered = true;
            });

            form_field.autocomplete({
                change_triggered: false,
                options: null,
                source: function (request, response) {
                    var arr = new Array();
                    for (var i = 0; i < form_field.autocomplete_options.length; i++) {
                        if (request.term == "" || form_field.autocomplete_options[i].toLowerCase().indexOf(request.term.toLowerCase()) !== -1) {
                            arr[arr.length] = form_field.autocomplete_options_descriptions[form_field.autocomplete_options[i]];
                        }
                    }
                    response(arr);
                },
                close: function (event, ui) {
                    $(this).blur();
                },
                change: function (event, ui) {
                    var found = false;
                    for (var key in form_field.autocomplete_options_descriptions) {
                        if (form_field.autocomplete_options_descriptions[key] == this.value) {
                            found = true;
                            this.value = key;
                            $(this).trigger('selected-existing-item');
                            break;
                        }
                    }
                    if (!found) $(this).trigger('selected-non-existing-item');

                    if (!form_field.change_triggered) form_field.trigger("change", [true]);
                    else form_field.change_triggered = false;
                },
                minLength: 0,
                open: function (event, ui) {
                    customizeAutocompletePresentation($(this));
                }
            });

            form_field.focus(function () {
                form_field.autocomplete("search", "");
                form_field.css('color', 'rgb(200, 200, 200)');
            });

            form_field.keydown(function () {
                form_field.css('color', 'rgb(0, 0, 0)');
            });

            form_field.focusout(function () {
                form_field.css('color', 'rgb(0, 0, 0)');
            });
        });
    }

    function initializeMultichoiceDropdown(element) {
        element.find('span[data-editable-dropdown="True"][data-multichoice-dropdown="True"]').each(function () {
            if ($(this).hasAttr('customed-autocomplete')) return;
            else $(this).attr('customed-autocomplete', 'true');

            var span = $(this);
            var select = $(this).children('select').eq(0);
            var form_field = $('<input>').prependTo($(this));

            var attributes = select.prop("attributes");
            form_field.autocomplete_options = Array();
            select.find('option').each(function () {
                form_field.autocomplete_options[form_field.autocomplete_options.length] = $(this).html();
            });

            $.each(attributes, function () {
                form_field.attr(this.name, this.value);
            });

            form_field.attr('value', select.find('option[selected]').html());
            select.remove();

            function multichoiceSelect(delement, jelement, val) {
                if (val == "") return false;

                var trigger_value = { parameter_value: val, return_value: false };

                jelement.trigger('checkIfAlreadySelected', [trigger_value]);
                if (trigger_value.return_value) return false;

                jelement.trigger('attachNewElement', [trigger_value]);

                var found = false;
                for (var i = 0; i < form_field.autocomplete_options.length; i++) {
                    if (form_field.autocomplete_options[i] == val) {
                        found = true;
                        jelement.trigger('selected-existing-item');
                        break;
                    }
                }
                if (!found) jelement.trigger('selected-non-existing-item');

                delement.value = "";
            }

            form_field.autocomplete({
                source: function (request, response) {
                    var arr = Array();
                    for (var i = 0; i < form_field.autocomplete_options.length; i++) {
                        if (request.term == "" || form_field.autocomplete_options[i].toLowerCase().indexOf(request.term.toLowerCase()) !== -1) {
                            if (!form_field.triggerHandler('checkIfAlreadySelected', [form_field.autocomplete_options[i]])) arr[arr.length] = form_field.autocomplete_options[i];
                        }
                    }
                    response(arr);
                },
                minLength: 0,
                select: function (event, ui) {
                    multichoiceSelect(this, $(this), ui.item.value);
                    return false;
                },
                open: function (event, ui) {
                    customizeAutocompletePresentation($(this));
                }
            }).keyup(function (e) {
                if (e.keyCode == 13) {
                    multichoiceSelect(this, $(this), $(this).val());
                }
            }).keydown(function (e) {
                if (e.keyCode == 9) {
                    multichoiceSelect(this, $(this), $(this).val());
                }
            });
        });
    }
};

$(document).ready(function () {
    $('body').initializeAutocomplete();
    $('body').on('new_data_taken', function () {
        $(this).initializeAutocomplete();
    });
    $(document).ajaxComplete($('body').initializeAutocomplete);
});