$.extend($.ri, {
    autocomplete: {
        customizeAutocompletePresentation: function (autocompleteObject) {
            autocompleteObject.FormField.addClass('ri-autocomplete-list-showed');

            autocompleteObject.FormField.autocomplete("widget").removeClass('ui-front ui-menu ui-widget ui-widget-content ui-corner-all');

            if (autocompleteObject.CSSClass != undefined && autocompleteObject.CSSClass != null && autocompleteObject.CSSClass != "")
                autocompleteObject.FormField.autocomplete("widget").addClass(autocompleteObject.CSSClass);
            else
                autocompleteObject.FormField.autocomplete("widget").addClass('ri-autocomplete');

            autocompleteObject.FormField.autocomplete("widget").find("li a").each(function () {
                $(this).removeClass('ui-corner-all');
            });
            autocompleteObject.FormField.autocomplete("widget").css('min-width', autocompleteObject.FormField.outerWidth());
            autocompleteObject.FormField.autocomplete("widget").css('width', 'auto');

            var i = 0;
            autocompleteObject.FormField.autocomplete("widget").children('li').each(function () {
                if (i >= autocompleteObject.MaxLengthVisibleOptions) {
                    $(this).addClass('ui-menu-description');
                    $(this).removeClass('ui-menu-item');
                }
                i++;
            });
        },

        isSelected: function (autocompleteObject, item) {
            var found = false;

            if (item.Item1 == 0) {
                autocompleteObject.List.find('input[name*="Name"]').each(function () {
                    if ($(this).val() == item.Item2) {
                        found = true;
                        return false;
                    }
                });
            } else {
                autocompleteObject.List.find('input[name*="Id"]').each(function () {
                    if ($(this).val() == item.Item1) {
                        found = true;
                        return false;
                    }
                });
            }
            return found;
        },

        select: function (autocompleteObject, longName, id) {

            if (!id)
                id = 0;

            if (longName == "" && !autocompleteObject.Multichoice && autocompleteObject.List.length > 0) {
                var oldId = autocompleteObject.List.find('input[name$="Id"]').val();
                autocompleteObject.List.find('li').remove();
                autocompleteObject.FormField.trigger('change', ['del', oldId]);
                return false;
            }
            else if (longName == "") return false;

            var item = Array();
            var found = false;

            if (autocompleteObject.ResultsNumber == 1) {
                item[0] = autocompleteObject.Ids[0];
                item[1] = autocompleteObject.Names[0];
                item[2] = autocompleteObject.LongNames[0];
                found = true;
            }
            else {
                for (var i = 0; i < autocompleteObject.LongNames.length; i++) {
                    if (autocompleteObject.LongNames[i] == longName) {
                        item[0] = autocompleteObject.Ids[i];
                        item[1] = autocompleteObject.Names[i];
                        item[2] = autocompleteObject.LongNames[i];
                        found = true;
                        break;
                    }
                    if (autocompleteObject.Names[i] == longName) {
                        item[0] = autocompleteObject.Ids[i];
                        item[1] = autocompleteObject.Names[i];
                        item[2] = autocompleteObject.LongNames[i];
                        found = true;
                        break;
                    }
                }
            }

            if (!found) autocompleteObject.FormField.trigger('selected-non-existing-item', [item]);
            else autocompleteObject.FormField.trigger('selected-existing-item', [item]);

            if (autocompleteObject.Multichoice) {
                autocompleteObject.FormField[0].value = "";

                if (found) {
                    if (!$.ri.autocomplete.isSelected(autocompleteObject, { Item1: item[0], Item2: item[1], Item3: item[2] })) $.ri.autocomplete.attachToList(autocompleteObject, item);
                } else if (autocompleteObject.Editable) {
                    item[0] = id;
                    item[1] = longName;
                    item[2] = longName;
                    if (!$.ri.autocomplete.isSelected(autocompleteObject, { Item1: item[0], Item2: item[1], Item3: item[2] })) $.ri.autocomplete.attachToList(autocompleteObject, item);
                }
            } else {
                if (!autocompleteObject.Editable && !found) {
                    autocompleteObject.FormField[0].value = "";
                } else if (found) {
                    $.ri.autocomplete.replaceItemIfExists(autocompleteObject, item);
                    autocompleteObject.FormField[0].value = item[1];
                } else {
                    item[0] = id;
                    item[1] = longName;
                    item[2] = longName;
                    $.ri.autocomplete.replaceItemIfExists(autocompleteObject, item);
                }
            }
        },

        attachToList: function (autocompleteObject, item) {
            $.get(autocompleteObject.NewmodelAction, { id: item[0], name: item[1], longname: item[2], multichoice: autocompleteObject.Multichoice }, function (data) {
                var element = $(data).appendTo($(autocompleteObject.List));
                element.find('input').each(function () {
                    var tryFindScopeInput = autocompleteObject.List.find('input.scope')
                    var scopeText = ''
                    if (tryFindScopeInput.length && tryFindScopeInput.val()) {
                        scopeText = autocompleteObject.List.find('input.scope').val()
                    } else {
                        console.warn(autocompleteObject)
                        var commonFind = $(autocompleteObject.List).closest('div').find('input.scope')
                        if (commonFind.length && commonFind.val()) {
                            scopeText = commonFind.val()
                        }
                    }
                    $(this).attr('name', scopeText + $(this).attr('name'));
                });

                autocompleteObject.FormField.trigger('change', ['add', item[0], item[1]]);
            });
        },

        replaceItemIfExists: function (autocompleteObject, item) {
            if (autocompleteObject.List.find('li').length >= 1) {
                var oldId = autocompleteObject.List.find('input[name$="Id"]').val();

                autocompleteObject.List.find('input[name$="Id"]').each(function () {
                    $(this).val(item[0]);
                });
                autocompleteObject.List.find('input[name$="Name"]').each(function () {
                    $(this).val(item[1]);
                });
                autocompleteObject.List.find('input[name$="Longname"]').each(function () {
                    $(this).val(item[2]);
                });
                autocompleteObject.FormField.trigger('change', ['del', oldId]);
            } else {
                $.ri.autocomplete.attachToList(autocompleteObject, item);
            }
            autocompleteObject.FormField.trigger('change', ['add', item[0], item[1]]);
        },
        settings: null,
        initialize: function (autocompleteAction, newmodelAction, formField, maxLengthVisibleOptions, showResultsNumber, editable, multichoice, list, allowExternalSelect, externalSelectAction, CSSClass, required) {
            var autocompleteObject = new Object();
            this.settings = autocompleteObject;
            autocompleteObject.AutocompleteAction = autocompleteAction;
            autocompleteObject.NewmodelAction = newmodelAction;
            autocompleteObject.FormField = formField;
            autocompleteObject.MaxLengthVisibleOptions = maxLengthVisibleOptions;
            autocompleteObject.ShowResultsNumber = showResultsNumber;
            autocompleteObject.Editable = editable;
            autocompleteObject.Multichoice = multichoice;
            autocompleteObject.List = list;
            autocompleteObject.ResultsNumber = 0;
            autocompleteObject.ExternalSelectAction = externalSelectAction;
            autocompleteObject.CSSClass = CSSClass;
            autocompleteObject.Required = required;

            autocompleteObject.Ids = Array();
            autocompleteObject.Names = Array();
            autocompleteObject.LongNames = Array();

            if (!autocompleteObject.Multichoice) {
                autocompleteObject.Ids[0] = list.find('input[name$="Id"]').val();
                autocompleteObject.Names[0] = list.find('input[name$="Name"]').val();
                autocompleteObject.LongNames[0] = list.find('input[name$="Longname"]').val();
            }

            if (allowExternalSelect) {
                var link = autocompleteObject.List.parent().find('.autocomplete-external-link');
                link.click(function (e) {
                    e.preventDefault();
                    var url = autocompleteObject.ExternalSelectAction;
                    $.get(url, {}, function (data) {
                        var modal = $.fn.showModal(data);
                        $(modal).unbind('modal-success').bind('modal-success', function (e) {
                            var ids = e.modalResult.ids;
                            var names = e.modalResult.name;
                            $.each(ids, function (i, id) {
                                $.ri.autocomplete.select(autocompleteObject, names[i], ids[i]);
                            });
                        });
                    });
                });
            }

            autocompleteObject.List.on('self-remove', '.autocomplete-element', function (e) {
                $(this).remove();
                var id = $(this).find('input[name$="Id"]').val();

                autocompleteObject.FormField.trigger('deleted-selected-item');
                autocompleteObject.FormField.trigger('change', ['del', id]);
            });

            autocompleteObject.FormField.autocomplete({
                source: function (request, response) {
                    $.post(autocompleteObject.AutocompleteAction + '?name=' + encodeURIComponent(autocompleteObject.FormField.val()) + '&numberOfElements=' + autocompleteObject.MaxLengthVisibleOptions, null, function (data) {
                        var resultsNumber;
                        if (data.length == 0) {
                            if (autocompleteObject.Editable) {
                                resultsNumber = new Array("No items found. Add new.");
                            } else {
                                resultsNumber = new Array("No items found");
                            }
                        }
                        else resultsNumber = new Array();
                        autocompleteObject.ResultsNumber = data.length;

                        var i = 0;
                        var arr = Array();
                        autocompleteObject.Ids = Array();
                        autocompleteObject.Names = Array();
                        autocompleteObject.LongNames = Array();
                        while (arr.length < autocompleteObject.MaxLengthVisibleOptions && i < data.length) {
                            if (!$.ri.autocomplete.isSelected(autocompleteObject, data[i]) || !autocompleteObject.Multichoice) {
                                autocompleteObject.Ids[arr.length] = data[i].Item1;
                                autocompleteObject.Names[arr.length] = data[i].Item2;
                                autocompleteObject.LongNames[arr.length] = data[i].Item3;
                                arr[arr.length] = autocompleteObject.LongNames[arr.length];
                            }
                            i++;
                        }

                        arr = resultsNumber.concat(arr);
                        if (data.length > autocompleteObject.MaxLengthVisibleOptions) {
                            arr[arr.length] = (data.length - autocompleteObject.MaxLengthVisibleOptions) + " items more";
                        }
                        response(arr);
                    });
                },
                minLength: 0,
                select: function (event, ui) {
                    $.ri.autocomplete.select(autocompleteObject, ui.item.value);
                    return false;
                },
                open: function (event, ui) {
                    $.ri.autocomplete.customizeAutocompletePresentation(autocompleteObject);
                },
                close: function (event, ui) {
                    autocompleteObject.FormField.removeClass('ri-autocomplete-list-showed');
                }
            }).keyup(function (e) {
                if (e.keyCode == 13) {
                    $.ri.autocomplete.select(autocompleteObject, $(this).val());
                }
            }).keydown(function (e) {
                if (e.keyCode == 9) {
                    $.ri.autocomplete.select(autocompleteObject, $(this).val());
                }
            }).focusout(function (e) {
                if (!autocompleteObject.Multichoice) {
                    $.ri.autocomplete.select(autocompleteObject, $(this).val());
                }
            });

            autocompleteObject.FormField.on('remove-item-from-list', function (event, index) {
                if (index === undefined) console.log("index shouldn't be undefined");
                autocompleteObject.List.find('input[name$="Id"]').each(function () {
                    if ($(this).val() == index) {
                        $(this).closest('.autocomplete-element').trigger('self-remove');
                    }
                });
            });

            autocompleteObject.FormField.on('select-with-add-item-to-list', function (event, id, shortName, longName) {
                //if (longName === undefined || shortName === undefined || id === undefined)
                //    console.log("Arguments (longName, shortName, id) should be defined");

                autocompleteObject.Ids[autocompleteObject.Ids.length] = id;
                autocompleteObject.LongNames[autocompleteObject.LongNames.length] = longName;
                autocompleteObject.Names[autocompleteObject.Names.length] = shortName;

                $.ri.autocomplete.select(autocompleteObject, longName);
            });

        }
    }
});