//simple but usefull extension to jquery
jQuery.fn.allData = function() {
    var intID = jQuery.data(this.get(0));
    return(jQuery.cache[intID]);
};

$.fn.getSimpleDataObject = function(){
    var out = {};
    var element = $(this);
    for (var key in element.data()) {
        var tmp = element.data(key);
        if ($.isNumeric(tmp) || (typeof tmp == "string") || typeof tmp == "boolean") {
            out[key] = tmp;
        }
    }
    return out;
}

$.fn.hasAttr = function (name) {
    if (name === 'checked') {
        if ($(this).prop(name) === 'undefined' || typeof $(this).prop(name) === 'undefined' || $(this).prop(name) === false) return false;
        else return true;
    }
    if ($(this).attr(name) === 'undefined' || typeof $(this).attr(name) === 'undefined' || $(this).attr(name) === false) return false;
    else return true;
};

$.fn.extend({
    modal: {
        show: function() {
            $.fancybox({
                padding: 0,
                content: $.fn.modal.modalEl,
                onStart: function() { //Will be called right before attempting to load the content
                    $($.fn.modal).trigger('modal-show');
                },
                onClosed: function() { //Will be called once FancyBox is closed
                    $($.fn.modal).trigger('modal-closed');
                },
                onComplete: function() { //Will be called once the content is displayed
                    //attaching back fancybox content to the modalEl, then we can use modalEl in success/failure handler with proper data
                    $.fn.modal.modalEl = $('#fancybox-wrap').find('#active-modal');
                    $('#fancybox-wrap').unbind('mousewheel.fb'); // this fix enables scroll when mouse is on fancybox
                    $($.fn.modal).trigger('modal-showed');                                      
                    //special for chrome, it fixes modal width but modal transition is visible on chrome
                    $('#fancybox-content, #fancybox-wrap').width($($.fn.modal.modalEl).width()); 
                    $.fn.modal.reposition(); 

                    if($.fn.modal.settings.preventClickToClose)
                        $('#fancybox-overlay').unbind('click');

                    if($.fn.modal.settings.overflow == 'visible')
                        $('#fancybox-content, #fancybox-content > div').css('overflow','visible');

                }, 
                onCleanup: function() { //Will be called just before closing
                    $($.fn.modal).trigger('modal-close');
                    $.fn.modal.modalEl.remove();
                    $.fn.modal.modalEl = $('<div>')
                        .addClass('modal')
                        .attr('id', 'active-modal');
                }
            });
        },
        close: function () {
            $($.fn.modal).trigger('modal-before-close');
            $.fancybox.close();

        },
        reposition: function() {
            //in fancybox 1.3.4 resizing is also doing repositioning
            $.fancybox.resize();
        },
        modalEl: $('<div>')
                .addClass('modal')
                .attr('id', 'active-modal'),
        settings : {}
    },

    showModal: function (html, options) {
        var settings = $.extend({
            'expand': false,
            'draggable': false,
            'modalSuccessFunctionControlsClosingModal': false,
            'size': 'medium', //options are small,medium,large
            'columns': 0,
            'overflow' : 'hidden', //options are hidden or visible
            'preventClickToClose': true,  // prevents closing when clicking OUTSIDE fancybox
            'contentColumns': 1,
            'appendTo': 'body', //jquery selector
            'successCallbackFunction' : function emptyFunction() { return true; } //default parameter is empty function
        }, options);

        $.fn.modal.modalEl.append(html);
        $.fn.modal.settings = settings;

        if (settings.expand)
            $.fn.modal.modalEl.addClass('expand');
        if (settings.draggable)
            $.fn.modal.modalEl.draggable();
        if (settings.size)
            $.fn.modal.modalEl.addClass(settings.size);
        if (settings.columns > 0) {
            $.fn.modal.modalEl.removeClass('small');
            var width = $(".main-panel").outerWidth() * (settings.columns / 12);
            $.fn.modal.modalEl.css("width", width + "px");
        }

        if (settings.contentColumns > 1) {
            $.fn.modal.modalEl.groupElements(settings.contentColumns);
        }
            
        $.fn.modal.show();
        $('#fancybox-wrap').appendTo($(settings.appendTo));

        $('#fancybox-wrap').initializeIndexes();

        $('.modal-success').bind('click', function(e) {
            if($(this).hasClass('disabled'))
                return;
            
            //for chrome - if in modal is form element chrome has unnecessary desire to post it after clicking button element with text Save
            e.preventDefault();
            var event = jQuery.Event('modal-success');
            event.modalResult = e.result;
            $($.fn.modal).trigger(event);

            if(settings.successCallbackFunction() || !settings.modalSuccessFunctionControlsClosingModal)
                $.fn.modal.close();
        });

        return $.fn.modal;
    },

    showInModal: function (options) {

        var settings = $.extend({
            'expand': false,
            'draggable': false,
            'getParams': null,
            'type': 'get',
            'beforeAjax': function () { return true; },
            'beforeShowInModal': function emptyFunction() { }, //default parameter is empty function
            'successCallbackFunction' : function emptyFunction() { return true; } //default parameter is empty function
        }, options);

        return this.each(function (i) {
            var object = $(this);
            $(this).unbind('click').click(function (e) {
                e.preventDefault();

                if ($(this).is('.disabled'))
                    return false;
                if (!settings.beforeAjax())
                    return false;

                var objectSettings = $.extend(settings, {});
                if ($(this).data('modal-size'))
                    objectSettings.size = $(this).data('modal-size');
                
                $.ajax(
                    
					$(this).attr('href'),
					{
                        'data': settings.getParams == null ? {} : settings.getParams(),
					    'type': settings.type,
					    'success': function (data, status, xhr) {

					        var header = xhr.getResponseHeader("X-MessagesOnly");
					        if (header != "" && header != undefined && header != null) {
					            return;
					        }
					        settings.beforeShowInModal(object);
					        return $.fn.showModal(data, objectSettings);
					    }
					});
            });
        });
    },

    showConfirmationModal: function(message, successCallback) {
        var p = $(document.createElement('h4'));
        p.text(message);
        var footer = '<div class="modal-footer"><div class="modal-buttons"><button class="button float-right modal-success">OK</button><button id="cancel-reveal-button" type="button" class="button float-right close-modal"> Cancel </button><div class="clear"></div></div></div>';

        return $.fn.showModal(p[0].outerHTML + footer, { 'size': 'small', 'successCallbackFunction' : successCallback });
    },

    groupElements: function (contentColumns) {

        return this.each(function (i) {

            var container = $(this);

            var sizeRatio = 0.8;
            var maxSizeLi = 240;
            var largeSizeColumn = (12 / contentColumns);
            var ul = container.find('ul.modal-content'); ;
            var li = container.find('ul').children('.modal-content li');
            ($(li).find('label')).addClass('labelRow');
            var a = $(li).children();
            var ulColumn = contentColumns;
            var liCount = Math.round((li.length + 1) / ulColumn);
            container.find('li.modal-content').detach();
            container.find('ul.modal-content').addClass('row');
            for (var i = 0; i < ulColumn; i++) {
                container.find('ul.modal-content').append('<li id="li-' + i + '" class="li-column width-' + largeSizeColumn + ' float-left"><ul id="ul-' + i + '" class="ul-column"></ul></li>')
            }
            container.find('.model-content row').append('<div class="clear"></div>');

            var p = 0;
            for (var i = 0; i < ulColumn; i++) {
                var g = 0;
                while (p < liCount) {
                    container.find('#li-' + i + '').find('#ul-' + i + '').append(li.eq(p))
                    g++;
                    p++;
                }
                liCount += g;
            }
        });
    },

    ajaxSubmit: function (options) {

        var settings = $.extend({
            'foo': 'bar'
        }, options);

        return this.each(function (i) {

            var form = $(this);
            form.validate();
            form.submit(function (e) {
                e.preventDefault();
                if (form.valid()) {
                    $.post(form.attr('action'), form.serialize(),
					function (data) {
					    var parent = form.parent();
					    form.parent().html(data);
					    if (parent.hasClass('reveal-modal')) {
					        $.ri.closeModal();
					    }
					});
                }
            });
        });
    },

    stateButton: function (options) {
        var settings = $.extend({
            'stateValueSelector': '.state-value',
            'textSelector': 'span'
        }, options);

        return this.each(function (i) {

            $(this).click(function () {
                if ($(this).hasClass('secondary')) {
                    $(this).removeClass("secondary");
                    $(this).find(settings.textSelector).html($(this).data("activatedtext"));
                    $(this).find(settings.stateValueSelector).val('true').trigger('change');
                    $(this).trigger($(this).data("activateevent"));
                }
                else {
                    $(this).addClass("secondary");
                    $(this).find(settings.textSelector).html($(this).data("disactivatedtext"));
                    $(this).find(settings.stateValueSelector).val('false').trigger('change');
                    $(this).trigger($(this).data("disactivateevent"));
                }
            });

        });

    },

    makeTabs: function (options) {
        return this.each(function (i) {
            
            var settings = $.extend({
                'enableButtonSwitching': true,
                'enableUrlHashSetting': true
            }, options);

            var container = $(this);
            if (container.hasAttr('data-tabs-initialized'))return;

            container.attr('data-tabs-initialized', true);

            //select first tab
            container.find('li:first').addClass('selected');

            //hiding unwanted tabs
            container.find('a, .a').each(function (j) {
                if (!$(this).parent().hasClass('selected'))
                    $('.tab-content[data-link-name="' + $(this).data('name') + '"]').hide();
            });

            var x = function (e) {

                if (!settings.enableUrlHashSetting)
                    e.preventDefault();

                if (e.keyCode && e.keyCode !== 13) {
                    return
                }

                var previousTabContent = $('.tab-content[data-link-name="' + container.find('li.selected a, li.selected .a').data('name') + '"]');
                container.find('li').removeClass('selected');
                $(this).parent().addClass('selected');
                container.find('a, .a').each(function (j, obj) {
                    if (!$(this).parent().hasClass('selected'))
                        $('.tab-content[data-link-name="' + $(this).data('name') + '"]').hide();
                });
                var newTabContent = $('.tab-content[data-link-name="' + $(this).data('name') + '"]').show();

                //if we are not in modal
                if (container.closest('#active-modal').size() < 1 && settings.enableButtonSwitching == true) {
                    //moving current buttons back to its tab
                    $('#sticky-section-header .title-buttons .tab-button').detach().appendTo(previousTabContent);
                    //moving new buttons to title
                    newTabContent.find('.tab-button').detach().appendTo('#sticky-section-header .title-buttons')
                }

                var e = jQuery.Event('tab_changed');
                e.tabs = container,
                e.newContent = newTabContent,
                e.previousContent = previousTabContent;
                container.trigger(e);
            };

            //bingind events
            container.find('li a, li .a').click(x);
            container.find('li a, li .a').keydown(x);

            //"clicking" selected tab
            container.find('li.selected a, li.selected .a').trigger('click');
        });

    }
});

$.ri = $.extend($.ri || {}, {

    closeModal: function () {
        $.fn.modal.close();
    },

    
    closeModalWithSuccess : function(result) {
        
        var event = jQuery.Event('modal-success');
        event.modalResult = result;
        $($.fn.modal).trigger(event);
        $.fn.modal.settings.successCallbackFunction();
        $.fn.modal.close();
    },

    showMessage: function (html, options) {
        var settings = $.extend({
            'duration': 4000,
            'cssClass': 'a'
        }, options);

        $('<div class="flash-message ' + settings.cssClass + '" style="display: none"><div>' + html + '</div></div>')
			.appendTo('body')
			.click(function () { $(this).stop().fadeOut(100, function () { $(this).remove(); }) })
			.fadeIn(500)
			.delay(settings.duration)
			.fadeOut(300, function () { $(this).remove(); });
    },

    showInfo: function (html, options) {

        var settings = $.extend({
            'cssClass': 'info'
        }, options);

        return $.ri.showMessage(html, settings);

    },

    showError: function (html, options) {

        var settings = $.extend({
            'cssClass': 'error'
        }, options);

        return $.ri.showMessage(html, settings);

    },

    showWarning: function (html, options) {

        var settings = $.extend({
            'cssClass': 'warning'
        }, options);

        return $.ri.showMessage(html, settings);

    },

    showIndicator: function () {
        if ($('#loading-indicator').size() < 1)
            $('body').append('<div id="loading-indicator"><div></div></div>');
        $('#loading-indicator').stop().fadeIn(200);
    },

    hideIndicator: function () {
        $('#loading-indicator').fadeOut(200);
    },

    isScrolledNearEnd: function () {
        return ($(window).scrollTop() + $(window).height() > $(document).height() - 100);
    },

    _getJSONCache: {},

    getJSONCached: function (url, data, callback, type) {

        if (!data)
            data = {};
        if (!type)
            type = null;
        if (!callback)
            return;

        var key = url + '.' + type + '.' + JSON.stringify(data);

        if ($.ri._getJSONCache[key]) {
            callback($.ri._getJSONCache[key]);
            $('body').trigger('new_data_taken');
            return;
        }

        $.getJSON(url, data, function (response) {
            $.ri._getJSONCache[key] = response;
            $('body').trigger('new_data_taken');
            callback($.ri._getJSONCache[key]);
        }, type);
    },


    _getCache: {},

    getCached: function (url, data, callback, type) {

        if (!data)
            data = {};
        if (!type)
            type = null;
        if (!callback)
            return;

        var key = url + '.' + type + '.' + JSON.stringify(data);

        if ($.ri._getCache[key]) {
            callback($.ri._getCache[key]);
            $('body').trigger('new_data_taken');
            return;
        }

        $.get(url, data, function (response) {
            $.ri._getCache[key] = response;
            $('body').trigger('new_data_taken');
            callback($.ri._getCache[key]);
        }, type);
    },


    guid : function() {

        function s4() {
          return Math.floor((1 + Math.random()) * 0x10000)
                     .toString(16)
                     .substring(1);
        };

        
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
                 s4() + '-' + s4() + s4() + s4();
        
    },


    makeStickyHeader: function (action) {

        $('#sticky-section').css({
            'position': 'fixed',
            'top': $('#top-bar').height()
        });

        $('#scrollable-section').css('margin-top', $('#sticky-section').height());
    },

    externalSelectCommonCallback: function (wrapper, id, name, mode) {
        var selectedInputName = wrapper.find('.selected-input-name');
        var nameInput = wrapper.find('.add-value').closest('span').find('.selected-input').attr("name");
        if(mode==="Change")
        {
            wrapper.find('.add-value').closest('span').find('.selected-input-name').detach();
            wrapper.find('.add-value').closest('span').find('.selected-input').detach();
        }
        var addValue = wrapper.find('.add-value');
        var removeValue = wrapper.find('.remove-value').first();
        var name = $.isArray(name) ? name : new Array(name);
        var id = $.isArray(id) ? id : new Array(id);
  
        for (var i = 0; i < id.length; i++) {
            var container =  addValue.parent();
            if(!(container).find($('.selected-input[value="'+id[i]+'"]')).length>0){
                $(container).find('.external-select-item-row').css("display", "block");
                var itemRow = $('<span class=external-select-item-row></span>');
                $(itemRow).append("<input type=text disabled=disabled class=selected-input-name value=\"" + name[i] + "\" />");
                if(mode==="AddOrRemove")
                    $(itemRow).append(removeValue.clone());
                $(itemRow).css("display", "block");
                $(itemRow).append("<input type=hidden id=selected-input class=selected-input name=" + nameInput + " value=" + id[i] + " />");
                addValue.before(itemRow);
            }
        }
            addValue.closest('span').find('.selected-input-name').last().css("display", "inline");
             addValue.closest('span').find('.external-select-item-row').last().css("display", "inline");
        $('.select-modal').removeClass('add-value');
    },
    removeExternalSelectRow: function(removeBtn){
        var row = $(removeBtn).closest('.external-select-item-row')
        var parent = row.parent()
        $(row).detach();
        $(parent).find('.external-select-item-row').last().css('display','inline')
    },
    confirmOnPageExit : function (e) {

        // If we haven't been passed the event get the window.event
        e = e || window.event;
        var message = ''; //no need for message
        // For IE6-8 and Firefox prior to version 4
        if (e) {
            e.returnValue = message;
        }
        // For Chrome, Safari, IE8+ and Opera 12+
        return message;
    },

    preSaveFormActions: function($form) {
        if(typeof $form === "undefined") {
            $form = $('form');
        }
        //tinymce is forced to copy editor content to textarea so jQuery validation can validate it.
        $('.inline-wysiwyg', $form).each(function(i, wysiwyg) {
            $('#' + $(wysiwyg).data('id'), $form).val(tinyMCE.get($(wysiwyg).attr('id')).getContent());
        });

        for (i in tinyMCE.editors) {
          var editor = tinyMCE.editors[i];
          if ($($(editor.getElement()), $form).length > 0) {
            editor.save();
          }
        }

    },

    getComputedStyle: function(elementDOM, propertyName)
    {
        if(window.getComputedStyle)
            return window.getComputedStyle(elementDOM, null).getPropertyValue(propertyName);
        else
            return "";
    }

//    externalSelectCommonUseAjax: function (wrapper, id, name, url, mode) {
//        var changeButton = $(wrapper).find('.select-modal.show-in-modal');
//        if (mode == "AddOrRemove") {
//            $.ajax({
//                url: url,
//                data: { name: name[i] },
//                type: 'GET',
//                success: function (data) {
//                    var removeButton = "<a class='font-size-medium color-alt remove-external-select-row' href=#>Remove</a>";
//                    $(wrapper).append(data);
//                    $(wrapper).find('input.selected-input-name').detach();
//                    $(wrapper).find('.select-modal.show-in-modal').detach();
//                    changeButton.text("Add");
//                    $(wrapper).find('.external-select-row').last().after(changeButton);
//                    $(wrapper).find('.external-select-row').last().append(removeButton);
//                }
//            });
//        }
//        if (mode == "Change") {
//            $(wrapper).find('.external-select-row').detach();
//            var nameInput = wrapper.find('.add-value').siblings('.selected-input').attr("name");
//            for (var i = 0; i < name.length; i++) {
//                $.ajax({
//                    url: url,
//                    data: { name: name[i] },
//                    type: 'GET',
//                    success: function (data) {
//                        $(wrapper).append(data);

//                        $(wrapper).find('input.selected-input-name').detach();
//                        $(wrapper).find('.external-select-row').last().append(changeButton);
////                        var fieldName = $(wrapper).find('.external-row-input ').first().attr("id");
////                        var inputName = "" + nameInput + "." + fieldName + "";
////                        $(wrapper).find('.external-row-input').last().attr("name", inputName);
////                        var dropFieldName = $(wrapper).find('select').first().attr("id");
////                        $('control select-container').children('select').last().attr("name", inputName);                        
//                    }
//                });
//            }

//        }
//    }
});

// [name] is the name of the event "click", "mouseover", .. 
// same as you'd pass it to bind()
// [fn] is the handler function
$.fn.bindFirst = function(name, fn) {
    // bind as you normally would
    // don't want to miss out on any jQuery magic
    this.on(name, fn);

    // Thanks to a comment by @Martin, adding support for
    // namespaced events too.
    this.each(function() {
        var handlers = $._data(this, 'events')[name.split('.')[0]];
        // take out the handler we just inserted from the end
        var handler = handlers.pop();
        // move it at the beginning
        handlers.splice(0, 0, handler);
    });
};

$.fn.serializeWithoutNamespace = function(scopeToRemove) {
    var data = this.serializeArray();
    var regex = new RegExp("^"+scopeToRemove + "\\.")
    for(var i=0; i<data.length;i++) {
        obj = data[i];
        obj = {name:obj.name.replace(regex, ''), value:obj.value};
        data[i]=obj;
    }
    return jQuery.param(data);
}